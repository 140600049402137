import React, { createContext, useEffect, useState } from "react";

const isBrowser = () => typeof window !== "undefined";

const defaultValues = {
  user: null,
};

export const WebsiteContext = createContext(defaultValues);

export const WebsiteProvider = ({ children }) => {
  const [distanceFromTop, setIsDistanceFromTop] = useState(0);
  return (
    <WebsiteContext.Provider value={{ distanceFromTop, setIsDistanceFromTop }}>
      {children}
    </WebsiteContext.Provider>
  );
};
